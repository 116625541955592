import React, { useState } from "react";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";

import Layout from "../components/Layout";
import Navbar from "../components/Navbar";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import Gallerypage from "../components/Gallerypage";
import Accordion from "react-bootstrap/Accordion";
const GalleryPage = () => {
  const [basicActive, setBasicActive] = useState('tab1');

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };
  return (
    <Layout pageTitle="KWEC | Gallery">
      <Navbar />
      <PageHeader title="Gallery" />

       <>
      <center className="container">
      <MDBTabs pills className='mb-3'>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleBasicClick('tab1')} active={basicActive === 'tab1'}>
          Student Workshop
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleBasicClick('tab2')} active={basicActive === 'tab2'}>
          Teachers Training Workshop
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleBasicClick('tab3')} active={basicActive === 'tab3'}>
          Handwriting without tears Workshop
          </MDBTabsLink>
        </MDBTabsItem>
      </MDBTabs>
      </center>

      <MDBTabsContent>
        <MDBTabsPane show={basicActive === 'tab1'}>  <Gallerypage/></MDBTabsPane>
        <MDBTabsPane show={basicActive === 'tab2'}>  <Gallerypage/></MDBTabsPane>
        <MDBTabsPane show={basicActive === 'tab3'}>  <Gallerypage/></MDBTabsPane>
      </MDBTabsContent>
    </>
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
