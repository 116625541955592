import image1 from "../assets/images/awards/image1.jpg";
import image4 from "../assets/images/awards/image4.jpg";
import image5 from "../assets/images/awards/image5.jpg";

export const galleryphotos = [
  {
    src: image1,
    title: "Caption",
    width: 4,
    height: 3,
  },
  {
    src: image5,
    title: "Caption",
    width: 3,
    height: 4,
  },
  {
    src: image4,
    title: "Caption",
    width: 4,
    height: 3,
  },
];
